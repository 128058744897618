import React from "react";
import { Row, Col, message, Card, Button, Spin } from "antd";
import { WarningOutlined } from "@ant-design/icons";
// import { withTranslation } from "react-i18next";
import 'antd/dist/antd.css'
import NotifItem from "./notif-item/NotificationItem";
import "./Home.scss";
import "./notificationsDirectory.styles.scss";
import "./notif-item/NotificationItem.styles.scss";
import rocketlogo from "./logo/logo-with-text.svg";
import axios from "axios";
// import { getHost } from "./LoginUser";
import { Link } from "react-router-dom";
class TestNotif extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: {
        schedule: false,
        reminder_title: false,
        pricedrop_title: null,
        welcome_title: false,
        backinstock: null,
      },
      currplan: {
        schedule: false,
        reminder_title: false,
        pricedrop_title: null,
        welcome_title: false,
        backinstock: null,
      }
    };
  }
//  
  componentDidMount() {
    
  }
  render() {
    // const locstore = JSON.parse(localStorage.getItem("profile")) || {plan:"none"};

    const { t } = this.props;
    const notifications = [
      {
        title: "Scheduled Festive Notification",
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2FFriends%20singing%20Christmas%20Carol-rafiki.png?alt=media&token=6655e7ee-ab43-429c-a252-ccdc3351ad46",
        id: 1,
        content:"Notifications based on time schedule",
        state: this.state.isActive.schedule,
        isAvailable: this.state.currplan.schedule,
        linkUrl: "scheduled",
        notif:{
            "buttons": [
                {
                    "link": "www.google.com/search?q=christmas",
                    "name": " Shop Now and Save!"
                }
            ],
            "Title": "Christmas Sale Alert!",
            "targetLink": "www.google.com/search?q=christmas",
            "message": " Don't miss out on our amazing Christmas sale! Get up to 50% off on all products and services.",
            "buttonName": "Check cart!",
            "buttonLink": "www.google.com/search?q=christmas",
            "imgsrc":"https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2Fchristmas.jpg?alt=media&token=d60c9110-54ad-4d0f-a8ed-6767cc4e63de",
            "logoimgsrc": "https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2FLogo%20Frame%20NOT%20ROUNDED%201200x1200%20(2).png?alt=media&token=a93a64a5-685e-4e91-b6be-eb7b70a7d4ed"
        }
      },
      {
        title:"Abandoned Cart Reminder Notification",
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2FAdd%20to%20Cart-amico.png?alt=media&token=40b11bd4-a6d6-44b9-8f83-0ea80d5f4314",
        id: 2,
        content: "Notifications sent to customers when they forget items in their cart",
        state: this.state.isActive.reminder_title,
        isAvailable: this.state.currplan.reminder_title,
        linkUrl: "cartrecovery",
        notif:{
            "buttons": [
                {
                    "link": "apps.shopify.com/rocketpush",
                    "name": "Check cart!"
                },
                {
                    "link": "apps.shopify.com/rocketpush",
                    "name": "View more!"
                }
            ],
            "Title": "You forgot to check out!",
            "targetLink": "apps.shopify.com/rocketpush",
            "message": "Buy them now before they get out of stock",
            "buttonName": "Check cart!",
            "buttonLink": "apps.shopify.com/rocketpush",
            "imgsrc":"https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2F2020_CartAbandonment_Blog.jpg?alt=media&token=93757c62-5703-4502-b1f9-7fa554bd4a4c",
            "logoimgsrc": "https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2FLogo%20Frame%20NOT%20ROUNDED%201200x1200%20(2).png?alt=media&token=a93a64a5-685e-4e91-b6be-eb7b70a7d4ed"
        }
      },

      {
        title: "Price Drop Notifications", //("notif.shipping.title"),
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2FPrice-cuate%20(1).png?alt=media&token=43a9e953-ffa8-4b00-b5f5-b47aafd19ddb",
        id: 3,
        content: "Notifications indicating the price drop or sale on your store",
        state: this.state.isActive.pricedrop_title,
        isAvailable: this.state.currplan.pricedrop_title,
        linkUrl: "pricedrop",
        notif:{
            "buttons": [
                {
                    "link": "apps.shopify.com/rocketpush",
                    "name": "Shop Now and Save Big!"
                }
            ],
            "Title": "Don't Miss Out on Savings!",
            "targetLink": "apps.shopify.com/rocketpush",
            "message": "We've dropped prices on a variety of products across our store. Check them out now and save on your favorite items.",
            "buttonName": "Shop Now and Save Big!",
            "buttonLink": "apps.shopify.com/rocketpush",
            "imgsrc":"https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2Fprice%20drop.png?alt=media&token=5d47324d-5abe-4ff4-9fdc-b4745d23d640",
            "logoimgsrc": "https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2FLogo%20Frame%20NOT%20ROUNDED%201200x1200%20(2).png?alt=media&token=a93a64a5-685e-4e91-b6be-eb7b70a7d4ed"
        }
      },
      {
        title: "Product Promotion Notifications", //("notif.shipping.title"),
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2FPrice-rafiki.png?alt=media&token=0df58e49-5f10-4683-8437-0f140de996b0",
        id: 6,
        content: "Notifications promoting your products",
        state: this.state.isActive.pricedrop_title,
        isAvailable: this.state.currplan.pricedrop_title,
        linkUrl: "pricedrop",
        notif:{
            "buttons": [
                {
                    "link": "apps.shopify.com/rocketpush",
                    "name": "Shop Now and Save Big!"
                }
            ],
            "Title": "Step Up Your Style Game!",
            "targetLink": "apps.shopify.com/rocketpush",
            "message": "Elevate your shoe collection with our new arrivals. From sneakers to sandals, we've got you covered.",
            "buttonName": "Shop Now and Save Big!",
            "buttonLink": "apps.shopify.com/rocketpush",
            "imgsrc":"https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2Fproduct%20promo.png?alt=media&token=b2926a42-2668-44b7-9aa9-de3d4c0e669f",
            "logoimgsrc": "https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2FLogo%20Frame%20NOT%20ROUNDED%201200x1200%20(2).png?alt=media&token=a93a64a5-685e-4e91-b6be-eb7b70a7d4ed"
        }
      },
      {
        title: "Pre-sale reminder Notifications", //("notif.shipping.title"),
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2FBlack%20Friday-rafiki.png?alt=media&token=599aa4bd-800c-48a8-8209-0763009621ff",
        id: 7,
        content: "Notifications to encourage users to visit your site once the sale starts.",
        state: this.state.isActive.pricedrop_title,
        isAvailable: this.state.currplan.pricedrop_title,
        linkUrl: "pricedrop",
        notif:{
            "buttons": [
                {
                    "link": "https://apps.shopify.com/rocketpush",
                    "name": "Shop Now and Save Big!"
                }
            ],
            "Title": "It's Almost Here!!",
            "targetLink": "apps.shopify.com/rocketpush",
            "message": "Only a few hours left for the sale to start.",
            "buttonName": "Shop Now and Save Big!",
            "buttonLink": "apps.shopify.com/rocketpush",
            "imgsrc":"https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2Fpresale.png?alt=media&token=a1fb1ccd-6459-4d3b-a881-4a705205dde3",
            "logoimgsrc": "https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2FLogo%20Frame%20NOT%20ROUNDED%201200x1200%20(2).png?alt=media&token=a93a64a5-685e-4e91-b6be-eb7b70a7d4ed"
        }
      },
      
      {
        title:"Welcome Notification",
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2Fwelcome.png?alt=media&token=ca192c24-a2b3-448c-a8fc-47bb6f693d50",
        id: 4,
        content:  "Notifications welcoming store subscribers",
        state: this.state.isActive.welcome_title,
        isAvailable: this.state.currplan.welcome_title,
        linkUrl: "welcome",
        notif:{
            "buttons": [
                {
                    "link": "apps.shopify.com/rocketpush",
                    "name": "Shop Now!"
                }
            ],
            "Title": "Welcome to Our Store!",
            "targetLink": "apps.shopify.com/rocketpush",
            "message": "Thank you for subscribing to our store! Get ready for exclusive discounts, new product launches, and special offers just for you.",
            "buttonName": "Shop Now!",
            "buttonLink": "apps.shopify.com/rocketpush",
            "imgsrc":"https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2Fwelcome.png?alt=media&token=ca192c24-a2b3-448c-a8fc-47bb6f693d50",
            "logoimgsrc": "https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2FLogo%20Frame%20NOT%20ROUNDED%201200x1200%20(2).png?alt=media&token=a93a64a5-685e-4e91-b6be-eb7b70a7d4ed"
        }
      },
      // {
      //   title: "Back in Stock Notification",
      //   imageUrl: "./backinstock.png",
      //   id: 5,
      //   content: "A notification indicating that a product is back in stock",
      //   state: this.state.isActive.backinstock,
      //   isAvailable: this.state.currplan.backinstock,
      //   linkUrl: "backinstock",
      //   notif:{
      //       "buttons": [
      //           {
      //               "link": "apps.shopify.com/rocketpush",
      //               "name": "Shop Now and Buy!"
      //           }
      //       ],
      //       "Title": "Back in Stock Alert!",
      //       "targetLink": "apps.shopify.com/rocketpush",
      //       "message": "We've restocked some of your favorite products! Hurry and get them now before they sell out again.",
      //       "buttonName": "Shop Now and Buy!",
      //       "buttonLink": "apps.shopify.com/rocketpush",
      //       "imgsrc":"https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2Fbackinstock.png?alt=media&token=6bd6a731-9463-4fe8-9699-b9a5363e6c89",
      //       "logoimgsrc": "https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/Rocket_demo_images%2FLogo%20Frame%20NOT%20ROUNDED%201200x1200%20(2).png?alt=media&token=a93a64a5-685e-4e91-b6be-eb7b70a7d4ed"
      //   }
      // },
    ];
    // const permission = Notification.permission;
    return (
      <Card className="home-card" style={{ height: "100%", background: "#0d83dd" }}
      bodyStyle={{ background: "white" }} title={
        <Row justify="center">
          <img
            src={rocketlogo}
            alt="brand"
            style={{
              height: "55px",
              width: "100%",
              padding: "0",
              margin: "0",
            }}
          ></img>
        </Row>
      }>
        <Row gutter={[24, 24]} justify="center">
          <Col span={24}>
            <h1 style={{ fontSize: "x-large",margin:"0px"}}>
                 Notification Use Cases
            </h1>
          </Col>

         

          {!(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) && (Notification.permission !== "granted") && (
            <Col span={24}>
              <Card
                className="home-card"
                style={{ background: "#FCF7E5" }}
                bodyStyle={{ padding: "20px", border: "10px" }}
              >
                <Row>
                  <Col xs={23} sm={22} md={2} lg={2} xl={2}>
                    {/* <WarningOutlined
                      style={{ fontSize: "30px", color: "orange" }}
                    /> */}
                  </Col>
                  <Col
                    xs={23}
                    sm={22}
                    md={18}
                    lg={18}
                    xl={18}
                    style={{ paddingTop: "5px" }}
                  >
                    {/* <a href={getHost()} target="_blank"> */}
                    Please allow notifications on this link to be able to test
                    notifications.
                    {/* </a> */}
                  </Col>
                  <Col
                    xs={23}
                    sm={22}
                    md={4}
                    lg={4}
                    xl={4}
                    style={{ paddingTop: "5px" }}
                  >

                  </Col>
                </Row>
              </Card>
            </Col>
          )}


          {(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) && (
            <Col span={24}>
              <Card
                className="home-card"
                style={{ background: "#FCF7E5" }}
                bodyStyle={{ padding: "20px", border: "10px" }}
              >
                <Row>
                  <Col xs={23} sm={22} md={2} lg={2} xl={2}>
                    {/* <WarningOutlined
                      style={{ fontSize: "30px", color: "orange" }}
                    /> */}
                  </Col>
                  <Col
                    xs={23}
                    sm={22}
                    md={18}
                    lg={18}
                    xl={18}
                    style={{ paddingTop: "5px" }}
                  >
                    {/* <a href={getHost()} target="_blank"> */}
                    IOS does not support push notifications yet. Please use a different device to test notifications.
                    {/* </a> */}
                  </Col>
                  <Col
                    xs={23}
                    sm={22}
                    md={4}
                    lg={4}
                    xl={4}
                    style={{ paddingTop: "5px" }}
                  >

                  </Col>
                </Row>
              </Card>
            </Col>
          )}

          {notifications.map(({ id, ...otherSectionProps }) => (
            <Col key={id} xs={23} sm={22} md={12} lg={8} xl={8}>
              <NotifItem {...otherSectionProps} />
            </Col>
          ))}
          
          <Col xs={23} sm={22} md={12} lg={8} xl={8}>
          <button className="prem-button" onClick={() => window.open("https://apps.shopify.com/rocketpush")} >
              Start Free Trial!
            </button>
          </Col>
        </Row>
    </Card>
    );
  }
}

export default (TestNotif);
