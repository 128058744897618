
import 'antd/dist/antd.min.css';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import Business from '../components/BusinessPlan';
import Plan from '../components/Plan';
import './Home.styles.scss';
import SkyWhiteLowQuality from "./images/sky-white-low-quality.jpg";
import SkyWhite from "./images/sky-white.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import RocketAnimeColor from "./images/rocket.svg";

const planList = [
  {
    id: 1,
    name: 'Basic',
    price: 'Free',
    suffixPrice: ' ',
    impression: '500',
    exclusive: [
      "Unlimited Subscribers",
      "Abandoned Cart Automation",
      "Welcome Notifications Automation",
      "Basic Reports",
      "Chat Support",
    ],
    buttonContent: 'Start free trial',
  },
  {
    id: 2,
    name: 'Premium',
    price: 9,
    suffixPrice: ' $ /mo',
    impression: 10000,
    exclusive: [
      "All FreeForever Features",
      "Unlimited Subscribers",
      "Send & Schedule Campaigns",
      "Highly customizable Schedule notifications",
      "High Impression Limit!",
      "Phone Support"
    ],
    price: 9,
    quota: 10000,
    trial: 7,
    buttonContent: 'Contact us',
    additional_quota_price_coefficient : 0.0009
  },
  {
    id: 3,
    name: 'Custom',
    price: "Get in touch with us",
    suffixPrice: '',
    impression: '',
    trial: 0,
    exclusive: [
      "Choose only the features that suit your store",
      "Custom Impression Limits",
      "Strategy Manager",
      "Integration Engineer"
    ],
    buttonContent: 'Contact us',
  },
];
const PricingPage = (props) => {

  
  return (
    <section className="relative">
    <div className="margin-auto">
      <div className="plan-tag-container">
        <span className="box-tag txt-24">Price</span>
      </div>
      <h4 className="txt-32 plan-container">
        Usage-Based Plans for Sites of All Sizes
      </h4>
      <div className="list-plan">
        <Plan plan={planList[0]} />
        <Business plan={planList[1]} />
        <Plan plan={planList[2]} />
      </div>
    </div>
    <div className={`rocket-quote__bottom `}>
      <LazyLoadImage
            src={RocketAnimeColor}
            className='rocket-lg' 
            alt="Rocket icon" />
        {/* <img src="rocket.svg" className="rocket-lg" /> */}
    </div>
    {/* <div className='bg-sky-white' style={{ background: 'url("sky-white.svg"'}}>
    </div> */}
    <div className='bg-sky-white'>
      <LazyLoadImage alt='white sky for decorate' className='img-full' src={SkyWhite} PlaceholderSrc={SkyWhiteLowQuality}  />
    </div>
  </section>
  );
};

PricingPage.propTypes = {};

export default PricingPage;
