// import {
//   DoubleLeftOutlined, DoubleRightOutlined, FacebookFilled, GithubOutlined,
//   TwitterCircleFilled
// } from '@ant-design/icons';
// import { Carousel } from 'antd';
import 'antd/dist/antd.min.css';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import Business from '../components/BusinessPlan';
import DynamicIcon from '../components/DynamicIcon';
import Plan from '../components/Plan';
import './Home.styles.scss';
import AvatarClientSecond from "./images/avartar-client-2.png";
import AvatarClientFirst from "./images/avatar-client-1.png";
import FeatureReportRevenue from "./images/discovery-3.png";
import FeatureOneLowQuality from './images/feature-1-low-quality.jpg';
import FeatureOneVarietyNoti from "./images/feature-1.svg";
import FeatureCustomColorLowQuality from "./images/feature-2-low-quality.jpg";
import FeatureCustomColor from "./images/feature-2.svg";
import SkyWhiteLowQuality from "./images/sky-white-low-quality.jpg";
import SkyWhite from "./images/sky-white.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import LazyLoadYoutubeFrame from '../components/LazyLoadYoutubeFrame';
import { LaptopNotification } from './frames/laptopNotification';
import CardChrome from "./images/card-chrome.png";
import CardIos from "./images/card-ios.png";
import RocketBackgroundBlue from "./images/rocket-blur.svg";
import RocketAnimeColor from "./images/rocket.svg";
import BackgroundIconWhite from "./images/background-icon.svg";
const clientComment = [
  {
    avatar: AvatarClientFirst,
    name: '1 Mike Frence Lee',
    title: '1 Great  team, great ',
    message:
      '1 Great work form the pushowl team! Super intuitive to set up custom campaigns for imediate dispatch or scheduling. The automation features are great and have been getting some good results for customer retention! Best of all the Customer service is completly on point, second to none on the response time for anything i have raised with them and always a solution on hand. There are some additional features that I require for our buisness & the team are more then happy to set about developing those for us! Great work TeamOwl',
  },
  {
    avatar: AvatarClientSecond,
    name: '2 Mike Frence Lee',
    title: '2 Great  team, great ',
    message:
      '2 Great work form the pushowl team! Super intuitive to set up custom campaigns for imediate dispatch or scheduling. The automation features are great and have been getting some good results for customer retention! Best of all the Customer service is completly on point, second to none on the response time for anything i have raised with them and always a solution on hand. There are some additional features that I require for our buisness & the team are more then happy to set about developing those for us! Great work TeamOwl',
  },
];
const planList = [
  {
    id: 1,
    name: 'Basic',
    price: 'Free',
    suffixPrice: ' ',
    impression: '500',
    exclusive: [
      "Unlimited Subscribers",
      "Abandoned Cart Automation",
      "Welcome Notifications Automation",
      "Basic Reports",
      "Chat Support",
    ],
    buttonContent: 'Start free trial',
  },
  {
    id: 2,
    name: 'Premium',
    price: 9,
    suffixPrice: ' $ /mo',
    impression: 10000,
    exclusive: [
      "All FreeForever Features",
      "Unlimited Subscribers",
      "Send & Schedule Campaigns",
      "Highly customizable Schedule notifications",
      "High Impression Limit!",
      "Phone Support"
    ],
    price: 9,
    quota: 10000,
    trial: 7,
    buttonContent: 'Contact us',
    additional_quota_price_coefficient : 0.0009
  },
  {
    id: 3,
    name: 'Custom',
    price: "Get in touch with us",
    suffixPrice: '',
    impression: '',
    trial: 0,
    exclusive: [
      "Choose only the features that suit your store",
      "Custom Impression Limits",
      "Strategy Manager",
      "Integration Engineer"
    ],
    buttonContent: 'Contact us',
  },
];
const HomePage = (props) => {
  const { ref: reasonRefFirst, inView: reasonVisibleFirst } = useInView();
  const { ref: reasonRefSecond, inView: reasonVisibleSecond } = useInView();
  const { ref: reasonRefThird, inView: reasonVisibleThird } = useInView();
  const { ref: cardBottom, inView: cardBottomVisible } = useInView();

  
  return (
    <div className="">
      {/* //! First */}
      <div>
        <section>
          <div className='overlay'>
            <div className='bg-intro'>
              <div className='bg-intro-left'></div>
              <div className='bg-intro-right'>
                {/* <img className='fullscreen-animation' src="new-bg-gif.gif" /> */}
                {/* <div className='fullscreen-animation'> */}
                  <LaptopNotification />
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className='bg-icon-main' >
            <div className="margin-auto">
              <div className="intro-container intro-app">
                <div className="intro-left align-center intro-left-title">
                  <div className=''>
                    <h2 className='txt-46'>Realtime notifications to your customers</h2>
                    <p className='txt-18'>
                    The ultimate solution for boosting your online sales and converting visitors into loyal customers. With Rocket Push, you can easily create customized push notifications that grab the attention of your audience and encourage them to take action.
                    Turn website visitors into subscribers without asking them personal information and continue to engage with them long after they leave your site.
                    </p>
                    <div className="intro-btn-container">
                      <button className="btn-primary">Signup for Free</button>
                      <button className="btn-secondary">
                        Request for free
                      </button>
                    </div>
                  </div>
                </div>
                {/* //! Image display in mobile*/}
                <div className="intro-right intro-img-container">
                  <div className='div-center'>
                  {/* <div className='flex-gif-intro'> */}
                    <div className='gif-mobile'>
                      <LaptopNotification />
                    </div>
                  {/* </div> */}
                    {/* <img className='flex-gif-intro' src="new-bg-gif.gif" /> */}
                    {/* <LazyLoadImage alt="text"  width={600} height={400} className='flex-gif-intro' src={require("./images/gif-demo.gif")} placeholderSrc={LaptopImg} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className='bottom-sky'  style= {{background: `url("sky.svg")` }}> 
        </div>
        <section className='section-video'>
          <div className='video-intro-section overflow-hidden '>
            <div  ref={cardBottom}>
              <div  className={` ${cardBottomVisible ? "show" : "hidden-left"} start-position`}>
                {/* <img className='card-float hidden-on-tablet' src="card-medium.svg" /> */}
                <LazyLoadImage
                  src={CardChrome}
                  className='card-float hidden-on-tablet' 
                  alt="Reference image when sending notifications on Chrome" />
              </div>
              <div>
                {/* <iframe loading='lazy' className='video-intro' height="550" src="https://www.youtube.com/embed/T33NN_pPeNI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                {/* <iframe frameborder="0" loading='lazy' className='video-intro' height="550" type="text/html" src="https://www.youtube.com/embed/DBXH9jJRaDk?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://youtubeembedcode.com"><div><small><a href="https://youtubeembedcode.com/nl/">youtubeembedcode.com/nl/</a></small></div><div><small><a href="https://skattefrittcasino.com/">casino skattefritt</a></small></div><div><small><a href="https://youtubeembedcode.com/nl/">youtubeembedcode nl</a></small></div><div><small><a href="https://skattefrittcasino.nu/">casino utan skatt</a></small></div></iframe> */}
                <LazyLoadYoutubeFrame videoId={'tOZoAsJO00w'} alt="Introductory video about rocketpush, an application that integrates into your Shopify store to send shopping information, promotions to customers" />
              </div>
              <div  className={`${cardBottomVisible ? "show" : "hidden-right"} end-position`}>
                {/* <img className='card-float hidden-on-tablet' src="card-small.svg" /> */}
                <LazyLoadImage
                  src={CardIos}
                  className='card-float hidden-on-tablet' 
                  alt="Reference image when sending notifications on IOS" />
              </div>
            </div>
          </div>
        </section>
        <section className="overflow-hidden relative">
          <div className='margin-auto'>
            <div className='discovery-wrapper'>
              <h3 className='discovery-title '>Why should you choose RocketPush? </h3>
              <div className='underline-transition'></div>
            </div>
            {/* //! Reason - 1 */}
            <div className='discovery-container'>
              <div ref={reasonRefFirst} className='intro-container discovery-card'>
                {/* //! Image in discovery */}
                <div className={`intro-left border-corner discovery-item-img-left ${reasonVisibleFirst ? "show" : "hidden-left"}`}  >
                  <LazyLoadImage className="intro-img" alt="Img feature 1" src={FeatureOneVarietyNoti} PlaceholderSrc={FeatureOneLowQuality} />
                  {/* <img className={`intro-img `} alt="rocketpush-feature" src={featureOne} /> */}
                </div>
                {/* //! Description  */}
                <div className={`intro-right border-corner ${reasonVisibleFirst ? "show" : "hidden-right"}`}  >
                  <div className="boxed" style={{ color: "#0f73dd", }}>
                    <DynamicIcon type={'RocketOutlined'} />
                  </div>
                  <div className={``}>
                    <h3 className={`title-discovery `}>Create notifications to your customers</h3>
                    <p className='description-discovery'>There are many types of notifications so you can engage users on a case-by-case basis</p>
                    <div className='short-line'></div>
                  </div>
                </div>
              </div>
              {/* //! Reason - 2 */}
              <div ref={reasonRefSecond} className='intro-container discovery-card'>
                <div className={`border-corner intro-right text-end ${reasonVisibleSecond ? "show" : "hidden-left"}`}>
                  <div className="boxed" style={{ color: "#0f73dd" }}>
                    <DynamicIcon type={'RocketOutlined'} />
                  </div>
                  <h3 className='title-discovery'>Customize notifications, schedules sent to customers easily</h3>
                  <p className='description-discovery'>Customize the notification configuration to suit your shop's style</p>
                  <div className='short-line'></div>
                </div>
                <div className={`border-corner intro-left ${reasonVisibleSecond ? "show" : "hidden-right"}`}>
                  {/* <img className='intro-img' alt="rocketpush-feature" src="feature-2.svg" /> */}
                  <LazyLoadImage className="intro-img" alt="Img feature 1" src={FeatureCustomColor} PlaceholderSrc={FeatureCustomColorLowQuality} />
              </div>
         
              </div>
             {/* //! Reason 3 */}
              <div ref={reasonRefThird} className='intro-container discovery-card'>
                {/* //! Image in discovery */}
                <div className={`intro-left border-corner discovery-item-img-left ${reasonVisibleThird ? "show" : "hidden-left"}`}  >
                  <LazyLoadImage className="intro-img" alt="Img feature 1" src={FeatureReportRevenue} />
                  {/* <img className={`intro-img `} alt="rocketpush-feature" src={featureOne} /> */}
                </div>
                {/* //! Description  */}
                <div className={`intro-right border-corner ${reasonVisibleThird ? "show" : "hidden-right"}`}  >
                  <div className="boxed" style={{ color: "#0f73dd", }}>
                    <DynamicIcon type={'RocketOutlined'} />
                  </div>
                  <div className={``}>
                    <h3 className={`title-discovery `}>Create notifications to your customers</h3>
                    <p className='description-discovery'>There are many types of notifications so you can engage users on a case-by-case basis</p>
                    <div className='short-line'></div>
                  </div>
                </div>
              </div>
          </div>
        </div>
          {/* //TODO: Update this background */}
          <div className='rocket-blur-right'>
            {/* <img src="rocket-blur.svg" /> */}
            <LazyLoadImage
                  src={RocketBackgroundBlue}
                  alt="Rocket symbol" />
          </div>
          <div className='rocket-blur-left'>
            {/* <img src="rocket-blur.svg" /> */}
            <LazyLoadImage
                  src={RocketBackgroundBlue}
                  alt="Rocket symbol" />
          </div>
        </section>
        {/* //! Quote */}
        {/* <section className=" section-white">
          <div className="quote-header margin-auto">
            <div className="quote-header-icon-container">
              <div className="boxed boxed-quote" style={{ color: '#0f83dd' }}>
                <img
                  src="quote.svg"
                  alt="icon-quote"
                  className=" icon-quote"
                  style={{ fontSize: '20px', width: '30px', height: '30px' }}
                />
              </div>
            </div>
            <h3 className="txt-28 customer-comment-title">Our Customers Say</h3>
            <div>
              <Carousel
                arrows
                prevArrow={<DoubleLeftOutlined />}
                nextArrow={<DoubleRightOutlined />}
              >
                {clientComment.map((item, idx) => {
                  return (
                    <div id={idx}>
                      <div className="client-comment">
                        <p className="client-comment__message">
                          {item.message}
                        </p>
                        <div className="client-comment__profile-container">
                          <img src={item.avatar} alt="user-avatar" />
                          <div className="client-name txt-16">
                            <p>{item.name}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </section> */}

        {/* //! Plan */}
        
        <section className="relative">
          <div className="margin-auto">
            <div className="plan-tag-container">
              <span className="box-tag txt-24">Price</span>
            </div>
            <h4 className="txt-32 plan-container">
              Usage-Based Plans for Sites of All Sizes
            </h4>
            <div className="list-plan">
              <Plan plan={planList[0]} />
              <Business plan={planList[1]} />
              <Plan plan={planList[2]} />
            </div>
          </div>
          <div className={`rocket-quote__bottom `}>
            <LazyLoadImage
                  src={RocketAnimeColor}
                  className='rocket-lg' 
                  alt="Rocket icon" />
              {/* <img src="rocket.svg" className="rocket-lg" /> */}
          </div>
          {/* <div className='bg-sky-white' style={{ background: 'url("sky-white.svg"'}}>
          </div> */}
          <div className='bg-sky-white'>
            <LazyLoadImage alt='white sky for decorate' className='img-full' src={SkyWhite} PlaceholderSrc={SkyWhiteLowQuality}  />
          </div>
        </section>
      
        
        <section className='section-video'>
            <div className='second-video-intro relative' >
              <div className='bg-icon-white-container'>
                <LazyLoadImage alt="background-white" className='' src={BackgroundIconWhite} />
              </div>
              {/* <iframe loading='lazy' className='video-intro ' style={{ height: "550px" }} src="https://www.youtube.com/embed/T33NN_pPeNI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  allowfullscreen></iframe> */}
              <LazyLoadYoutubeFrame videoId={'tOZoAsJO00w'} alt="Introductory video about rocketpush, an application that integrates into your Shopify store to send shopping information, promotions to customers" />
            </div>
        </section>

        {/* Get on SHOPIFY */}
        <section className=" section-blue">
          <div className='bg-gradient'>
            <div className='get-shopify-container'>
              <div className="margin-auto">
                <h3 className="txt-32 connect-shopify-title">
                  Earn more revenue and recover abandoned carts easily!
                </h3>
                <button className="btn btn-secondary">GET IT ON SHOPIFY</button>
              </div>
            </div>
            {/* <LazyBackground className='get-shopify-container' src='background-icon-blue.svg'>
              <div className="margin-auto">
                <h3 className="txt-32 connect-shopify-title">
                  Earn more revenue and recover abandoned carts easily!
                </h3>
                <button className="btn btn-secondary">GET IT ON SHOPIFY</button>
              </div>
            </LazyBackground> */}
          </div>
        </section>
      </div>
    </div>
  );
};

HomePage.propTypes = {};

export default HomePage;
