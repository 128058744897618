import React from 'react';

import {
    ShoppingCartOutlined,
  ShoppingOutlined,
  UserSwitchOutlined,
  ClusterOutlined,
  AlertOutlined,
  CoffeeOutlined,
  RocketOutlined
} from '@ant-design/icons';

const DynamicIcon = ({ type, ...rest }) => {
  const getIcon = (iconType) =>
    ({
      ShoppingCartOutlined: <ShoppingCartOutlined {...rest} />,
      ShoppingOutlined: <ShoppingOutlined {...rest} />,
      UserSwitchOutlined: <UserSwitchOutlined {...rest} />,
      ClusterOutlined: <ClusterOutlined {...rest} />,
      AlertOutlined: <AlertOutlined {...rest} />,
      CoffeeOutlined: <CoffeeOutlined {...rest} />,
    }[iconType]);
  return getIcon(type) ||<RocketOutlined {...rest} />;
};

export default DynamicIcon;
