import React from 'react';
import '../pages/Home.styles.scss';
import { RocketOutlined } from '@ant-design/icons';
import { useState } from 'react';
import {
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import './Plan.styles.scss';
const Business = ({ plan }) => {
const [impression, setImpression] = useState(plan["impression"] || 10000);
const onAddImpression = () => {
  if (impression < 50000) {
  setImpression(impression + 10000);
  }
}
const onMinusImpression = () => {
  if (impression > 10000) {
    setImpression(impression - 10000);
  }
}

  return (
    <>
      <div
        className={`plan-item ${
          plan.name === 'Business' ? 'plan-item-selected' : ''
        }`}
      >
        <div>
          {/* Logo Plan */}
          <div className="plan-header padding-lr">
            <div
              className="plan-header__icon boxed"
              style={{ color: '#0f83dd' }}
            >
              {<RocketOutlined className="icon" />}
            </div>
            <div className="plan-header__price">
              <h3 className="txt-24">{plan.name}</h3>
              <div className="flex-start">
              <>
                <span className="txt-28">{(Number(impression) - Number(plan.impression)) *  Number(plan.additional_quota_price_coefficient) + Number(plan.price)}</span>
                <span className="txt-16">{plan.suffixPrice}</span>
              </>
              </div>
            </div>
          </div>

          {/* Impression per month */}
          <div className="impression align-space-around ">
            <div  className='icon-container align-center' onClick={onMinusImpression}>
              <MinusOutlined style={{ color: "#0f73dd", fontSize: "12px"}} />
            </div>
            <div>
              <h3 className="txt-18">{impression}</h3>
              <p className="txt-18">Impression per month</p>
            </div>
            <div className='icon-container align-center' onClick={onAddImpression}>
              <PlusOutlined  style={{ color: "#0f73dd", fontSize: "12px"}}/>
            </div>
          </div>
          {/* Values */}
          <div className="exclusive-container  padding-lr">
            <ul>
              {plan.exclusive.map((excl, idx) => {
                return <li className="txt-16 exclusive-item"><span>{excl}</span></li>;
              })}
            </ul>
          </div>
          <div className="exclusive-btn-container ">
            <button className="btn btn-primary btn-full-mobile">
              {plan.buttonContent}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Business;
