import './App.css';
import "./index.css";
import "./App.scss"
import React, { lazy, Suspense } from "react";
// import Testnotifs from "./containers/TestNotifs";
import HomePage from './pages/HomePage';
import Privacy from './pages/Privacy';
import Gdpr from './pages/Gdpr';
import Pricing from './pages/Pricing'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Layout from './components/Layout';
import TestNotif from './containers/TestNotifs';

 function App() {
    window.dataLayer = window.dataLayer || [];
    
    function urlBase64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");
    
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
    
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    }
    
    function dynamicallyLoadScript(url) {
      const script = document.createElement("script"); // create a script DOM node
      script.src = url; // set its src to the provided URL
    
      document.head.appendChild(script); // add it to the end of the head section of the page (could change 'head' to 'body' to add it to the end of the body section instead)
    }

    function addHTMLOverlay() {
      var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if(!isIOS){
      const overlay =
        '<div style=" position: fixed;' +
        "  display: none;" +
        "  width: 100%;" +
        "  height: 100%;" +
        "  top: 0;" +
        "  left: 0;" +
        "  right: 0;" +
        "  bottom: 0;" +
        "  background-color: rgba(0,0,0,0.7);" +
        "  z-index: 30; " +
        '  cursor: pointer;" ' +
        'id="rocket-push-overlay">' +
        '<div style="position: absolute; top: 50%; left: 50%; color: white; transform: translate(-50%,-50%); ' +
        '  -ms-transform: translate(-50%,-50%);">' +
        '<svg style="height: 150px; margin-left: -25px;" data-name="1-Arrow Up" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="white" d="m26.71 10.29-10-10a1 1 0 0 0-1.41 0l-10 10 1.41 1.41L15 3.41V32h2V3.41l8.29 8.29z"/></svg>' +
        '<p style="font-size: 50px; margin: 10px 0;">Click Allow</p><p style="font-size: 18px;">You will be able to test notifications on allowing</p>' +
        "</div>" +
        "</div>";
      document.body.insertAdjacentHTML("afterbegin", overlay);
      }
      else{
        console.log("IOS not supported")
      }
    }
    
    function turnOnOverlay() {
      document.getElementById("rocket-push-overlay").style.display = "block";
    }
    
    function turnOffOverlay() {
      document.getElementById("rocket-push-overlay").style.display = "none";
    }
    
    async function main() {
      const ROCKET_PUSH_PUBLICVAPIDKEY = "BDPRJW28WU4vyeRJB_C26j0YPzFBBNtFM5fEFOClimPyQcYgcc_OcHPN2UAZsdtMyTCdvmeUBFhuwYvuXuciYoE";
    
      const API_ROCKET_PUSH_BASE_URL = "https://app.rocketpush.io"; 
      
      const API_ROCKET_PUSH_STORE = "alishdevstore.myshopify.com";

      const fetchFunction = window.fetch;
    
      // send request with fetch
      function sendRequest(
        endpoint,
        method = "GET",
        { body, ...customConfig } = {}
      ) {
        const headers = { "Content-Type": "application/json" };
        const config = {
          method: method,
          ...customConfig,
          headers: {
            ...headers,
            ...customConfig.headers,
          },
          mode: "cors",
        };
        if (body) {
          config.body = JSON.stringify(body);
        }
        return fetchFunction(`${endpoint}`, config)
          .then(async (response) => {
            if (response.ok) {
              return await response.json();
            } else {
              const errorMessage = await response.text();
              return Promise.reject(new Error(errorMessage));
            }
          })
          .catch((err) => console.error(err));
      }
    
      async function rocket_push_register(register) {
        // Register Push
        console.log("Registering new enpoint");
        // const domainresult = await getShopifyDomain();
        window.rocket_push_subscription = await register.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(ROCKET_PUSH_PUBLICVAPIDKEY),
        });
        console.log(window.rocket_push_subscription);
        localStorage.setItem(
          "rocket_push_subscription",
          JSON.stringify(window.rocket_push_subscription)
        );

         //pushSubscription
      const PushSubscriptionData = {
        pushsub_config: JSON.stringify(window.rocket_push_subscription),
      };

      const res = await sendRequest(
        `${API_ROCKET_PUSH_BASE_URL}/api/setWebPushSub`,
        "POST",
        { body: PushSubscriptionData }
      );
      console.log("res is : ",res);
      if (res?.id && res.id != undefined) {
        localStorage.setItem("rocket_push_pushsub_id", res.id);
      }
      }
      async function getConfiguration() {
        try {
          // const data = await sendRequest(
          //   `${API_ROCKET_PUSH_BASE_URL}/api/configuration?domain=${API_ROCKET_PUSH_STORE}`
          // );
          const data= [{"id":3,
          "configuration":"{\"domain\":\"alishdevstore.myshopify.com\",\"timingSetup\":{\"desktop\":2,\"mobile\":2},\"intermediatePrompt\":{\"show\":true,\"title\":\" Allow Notifications\",\"description\":\"Please enable notifications so that you can have a seamless experience with us.\"},\"buttonAllow\":{\"title\":\"Allow\",\"bgcolor\":\"#ff0000\",\"textcolor\":\"#ffffff\"},\"buttonLater\":{\"title\":\"Later\",\"bgcolor\":\"#ffffff\",\"textcolor\":\"#fd0000\"},\"optinTimings\":{\"desktop\":2,\"mobile\":2,\"maxpromptcount\":3,\"frequency\":1},\"browserPrompt\":{\"enableoverlay\":true},\"widgetText\":{\"before\":\"We would like to share our product.\",\"after\":\"Thank you for subscribing\",\"button\":\"Subscribe\"}}","shop_domain":"alishdevstore.myshopify.com"}]
          return JSON.parse(data[0].configuration);
        } catch (err) {
          console.log(err);
        }
      }
    
    
      async function getSetting() {
        try {
          // const data = await sendRequest(
          //   `${API_ROCKET_PUSH_BASE_URL}/api/shop/settings?domain=${API_ROCKET_PUSH_STORE}`
          // );
          const data= {"userName":"","companyName":"","webLink":"","logoimgsrc":"https://firebasestorage.googleapis.com/v0/b/rocket-346418.appspot.com/o/images%2FYZ7pjZhUb124VeZlta0A--grid.png7de2d3bb-be97-4d62-b8df-fdd6f71d3b37?alt=media&token=027c2631-a1f5-41c9-aeab-90f16f1197b1","GDPR":false}
          return data;
        } catch (err) {
          console.log(err.message);
        }
      }
      //////////////////////////////////////////////////////////////////////////
    
      if ("serviceWorker" in navigator && !(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream)) {
        
        navigator.serviceWorker
          .register(`/worker.js`)
          .then(async (register) => {
            console.log("Service worker registered!");
            if (
              !localStorage.getItem("allow_notification") ||
              localStorage.getItem("allow_notification") != 1 || Notification.permission !== "granted"
            ) {
              const isMobile = window.matchMedia("(any-pointer:coarse)").matches;
    
              let countShowSubscriberPrompt = 0;
    
              // load sweetalert2 script
              dynamicallyLoadScript("//cdn.jsdelivr.net/npm/sweetalert2@11");
    
              // store setting
              const storeSetting = await getSetting(API_ROCKET_PUSH_BASE_URL);
              const logoImage = storeSetting?.logoimgsrc
                ? storeSetting.logoimgsrc
                : "";
    
              // subscriber prompt
              const promptConfig = await getConfiguration(API_ROCKET_PUSH_BASE_URL);
    
              // insert overlay HTML
              let enableOverlay = false;
              if (
                promptConfig?.browserPrompt &&
                promptConfig.browserPrompt.enableoverlay
              ) {
                enableOverlay = true;
                addHTMLOverlay();
              } else {
                enableOverlay = false;
              }
    
              if (!promptConfig || !promptConfig?.intermediatePrompt) {
                console.log("Browser prompt does not have configuration");
                enableOverlay && turnOnOverlay();
                const permission = await Notification.requestPermission();
                if (permission === "granted") {
                  enableOverlay && turnOffOverlay();
                  try {
                    await rocket_push_register(register);
                  } catch (err) {
                    console.error(err);
                  }
                  localStorage.setItem("allow_notification", 1);
                  console.log("Subscription saved");
                } else {
                  enableOverlay && turnOffOverlay();
                }
              } else {
                const isShowSubscriberPrompt = promptConfig.intermediatePrompt.show;
                const subscriberFrequency = promptConfig.optinTimings.frequency; //days
                const subscriberTiming = isMobile
                  ? promptConfig.optinTimings.mobile
                  : promptConfig.optinTimings.desktop; //seconds
                const maximunSubscriberPrompt =
                  promptConfig.optinTimings.maxpromptcount;
    
                if (isShowSubscriberPrompt) {
                  let askTime = false;
                  let isPauseInterval = false;
                  const showSubscriberPromptInterval = setInterval(() => {
                    // if (!isPauseInterval) {
                    //   askTime =
                    //     Date.now() -
                    //       parseInt(
                    //         localStorage.getItem("ask_time")
                    //           ? localStorage.getItem("ask_time")
                    //           : "0"
                    //       ) >=
                    //     subscriberFrequency * 86400 * 1000;
    
                    //   if (
                    //     askTime &&
                    //     countShowSubscriberPrompt < maximunSubscriberPrompt
                    //   ) {
                    //     isPauseInterval = true;
                    //     setTimeout(showSubscriberPrompt, subscriberTiming * 1000);
                    //   }
                    //   if (countShowSubscriberPrompt >= maximunSubscriberPrompt) {
                    //     isPauseInterval = true;
                    //   }
                    // }
                    if (!isPauseInterval) {
                      askTime =
                        Date.now() -
                          parseInt(
                            localStorage.getItem("ask_time")
                              ? localStorage.getItem("ask_time")
                              : "0"
                          ) >=
                        0 * 86400 * 1000;
    
                      if (
                        askTime 
                      ) {
                        isPauseInterval = true;
                        setTimeout(showSubscriberPrompt, subscriberTiming * 1000);
                      }
                    }
                  }, 1000);
                  const showSubscriberPrompt = () => {
                    countShowSubscriberPrompt += 1;
                    window.Swal.fire({
                      position: "top",
                      width: "400px",
                      html:
                        "<style> .custom-btn{ padding: 10px 20px; } </style>" +
                        '<div style="display: flex; flex-direction: row;">' +
                        '<img src="' +
                        logoImage +
                        ' " width="auto" height="50px" />' +
                        '    <div style="margin-left: 10px; text-align: left">' +
                        '    <span style="font-weight: bold; font-size: 18px">' +
                        promptConfig.intermediatePrompt.title +
                        "        </span>" +
                        '        <p style="font-size: 14px; margin: 10px 0;">' +
                        promptConfig.intermediatePrompt.description +
                        "        </p>" +
                        "    </div>" +
                        "</div>",
                      showCloseButton: true,
                      showCancelButton: true,
                      focusConfirm: true,
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                      confirmButtonColor: promptConfig.buttonAllow.bgcolor,
                      cancelButtonColor: promptConfig.buttonLater.bgcolor,
                      customClass: {
                        cancelButton: "custom-btn",
                        confirmButton: "custom-btn",
                      },
                      confirmButtonText:
                        '<span style="color: ' +
                        promptConfig.buttonAllow.textcolor +
                        ';">' +
                        promptConfig.buttonAllow.title +
                        "</span>",
                      cancelButtonText:
                        '<span style=" color: ' +
                        promptConfig.buttonLater.textcolor +
                        ' ">' +
                        promptConfig.buttonLater.title +
                        "</span>",
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        isPauseInterval = true;
                        enableOverlay && turnOnOverlay();
                        const permission = await Notification.requestPermission();
                        if (permission === "granted") {
                          enableOverlay && turnOffOverlay();
                          try {
                            await rocket_push_register(register);
                          } catch (err) {
                            console.error(err);
                          }
                          localStorage.setItem("allow_notification", 1);
                          console.log("Subscription saved");
                        } else {
                          enableOverlay && turnOffOverlay();
                        }
                      } else {
                        localStorage.setItem("ask_time", Date.now());
                        isPauseInterval = false;
                      }
                    });
                  };
                } else {
                  enableOverlay && turnOnOverlay();
                  const permission = await Notification.requestPermission();
                  if (permission === "granted") {
                    enableOverlay && turnOffOverlay();
                    try {
                      await rocket_push_register(register);
                    } catch (err) {
                      console.error(err);
                    }
                    localStorage.setItem("allow_notification", 1);
                    console.log("Subscription saved");
                  } else {
                    enableOverlay && turnOffOverlay();
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.warn("Error registering service worker:");
            console.warn(error);
          });
      }
    }
    
    main();
    
  
  return (
    <div className="App">
      <Layout>
        {/* <Suspense fallback={<div>Loading...</div>}> */}
          <Switch>
              <Route path={'/'} ><TestNotif/></Route>
              <Route path={'/privacy'} component={Privacy}></Route>
              <Route path={'/pricing'} component={Pricing}></Route>
              <Route path={'/gdpr'} component={Gdpr}></Route>
              <Route path="/fr" component={HomePage}></Route>
          </Switch>
        {/* </Suspense> */}
        </Layout>
    </div>
  );
}

export default App;
