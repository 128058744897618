import React from 'react'
import "./LazyLoadYoutubeFrame.styles.scss"
const LazyLoadYoutubeFrame = ({ src, videoId, alt,  }) => {
  
  return (
    <div>
        <iframe
        title="Youtube"
        aria-hidden="true"
        className={"video-intro"}
        height={'550px'}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,
        body{}img, .play-icon {position:absolute;width:100%;top:0;bottom:0;margin:auto}
        .btn-video-container { display: flex; align-items: center; justify-content: center; text-align:center;}
        svg { color: #0f73dd}
        svg:hover {
            color: #DFF1FF;
        }
        .play-icon { width: 42px; height:65px;
            }</style>
        <a href=https://www.youtube.com/embed/${videoId}/?autoplay=1>
        <img src=https://img.youtube.com/vi/${videoId}/hqdefault.jpg alt='${alt}'><div class="btn-video-container">
        <div class="play-icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" fill="currentColor" /></svg>
        </div>
        </div></a>`}
        ></iframe>
  </div>
  )
}


export default LazyLoadYoutubeFrame