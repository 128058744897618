import React from 'react';
import '../pages/Home.styles.scss';
import { RocketOutlined } from '@ant-design/icons';
import "./Plan.styles.scss";
const Plan = ({ plan }) => {
  return (
    <>
      <div
        className={`plan-item ${plan.name === 'Business' ? 'plan-item-selected' : '' }`}
      >
        <div>
          {/* Logo Plan */}
          <div className="plan-header padding-lr">
            <div
              className="plan-header__icon boxed"
              style={{ color: '#0f83dd' }}
            >
              {<RocketOutlined className="icon" />}
            </div>
            <div className="plan-header__price">
              <h3 className="txt-24">{plan.name}</h3>
              <div className="flex-start">
                { plan.id != 3 && <><span className="txt-28 txt-blue">{plan.price}</span>
                <span className="txt-16">{plan.suffixPrice}</span></> }
                { plan.id === 3 && <div className='get-in-touch'>
                  <p className=' txt-18'>{plan.price}</p>
                </div>}
              </div>
            </div>
          </div>

          {/* Impression per month */}
          <div className="impression">
            <h3 className="txt-18">{plan.impression}</h3>
            <p className="txt-18">Impression per month</p>
          </div>
          {/* Values */}
          <div className="exclusive-container  padding-lr">
            <ul>
              {plan.exclusive.map((excl, idx) => {
                return <li className="txt-16 exclusive-item"><span>{excl}</span></li>;
              })}
            </ul>
          </div>
          <div className="exclusive-btn-container ">
            <button className="btn btn-secondary btn-full-mobile">
              {plan.buttonContent}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plan;
