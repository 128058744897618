// import {
//   DoubleLeftOutlined, DoubleRightOutlined, FacebookFilled, GithubOutlined,
//   TwitterCircleFilled
// } from '@ant-design/icons';
// import { Carousel } from 'antd';
import 'antd/dist/antd.min.css';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import Business from '../components/BusinessPlan';
import DynamicIcon from '../components/DynamicIcon';
import Plan from '../components/Plan';
import './Home.styles.scss';
import AvatarClientSecond from "./images/avartar-client-2.png";
import AvatarClientFirst from "./images/avatar-client-1.png";
import FeatureReportRevenue from "./images/discovery-3.png";
import FeatureOneLowQuality from './images/feature-1-low-quality.jpg';
import FeatureOneVarietyNoti from "./images/feature-1.svg";
import FeatureCustomColorLowQuality from "./images/feature-2-low-quality.jpg";
import FeatureCustomColor from "./images/feature-2.svg";
import SkyWhiteLowQuality from "./images/sky-white-low-quality.jpg";
import SkyWhite from "./images/sky-white.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import LazyLoadYoutubeFrame from '../components/LazyLoadYoutubeFrame';
import { LaptopNotification } from './frames/laptopNotification';
import CardChrome from "./images/card-chrome.png";
import CardIos from "./images/card-ios.png";
import RocketBackgroundBlue from "./images/rocket-blur.svg";
import RocketAnimeColor from "./images/rocket.svg";
import BackgroundIconWhite from "./images/background-icon.svg";

const Gdpr = (props) => {
 
  
  return (
    <div className="" style={{margin:"100px",marginTop:"150px",textAlign:"left"}}>
      <h1>Data Processing Agreement</h1>
     
     Updated Date : 27 November 2020
    This Data Processing Addendum (“DPA”), forms part of the Agreement between Teqie Inc. d/b/a RocketPush(“RocketPush”) and Customer (“Customer”) and shall be effective on the date both parties execute this DPA (Effective Date”). All capitalized terms not defined in this DPA shall have the meanings set forth in the Agreement.
    <h2>1. Definitions
   </h2> 
   <ul>
    <li>“Affiliate” means an entity that directly or indirectly Controls, is Controlled by or is under common Control with an entity.
  </li>
    <li>“Agreement” means RocketPush’s Terms of Use, which govern the provision of the Services to Customer, as such terms may be updated by RocketPush from time to time.
  </li> <li>“Control” means an ownership, voting or similar interest representing fifty percent (50%) or more of the total interests then outstanding of the entity in question. The term “Controlled” shall be construed accordingly.
   </li><li>“Customer Data” means any Personal Data that RocketPush processes on behalf of Customer as a Data Processor in the course of providing Services, as more particularly described in this DPA.
   </li><li>“Data Protection Laws” means all data protection and privacy laws applicable to the processing of Personal Data under the Agreement, including, where applicable, EU Data Protection Law.
   </li><li>“Data Controller” means an entity that determines the purposes and means of the processing of Personal Data.
   </li><li>“Data Processor” means an entity that processes Personal Data on behalf of a Data Controller.
   </li><li>“EU Data Protection Law” means (i) prior to 25 May 2018, Directive 95/46/EC of the European Parliament and of the Council on the protection of individuals with regard to the processing of Personal Data and on the free movement of such data (“Directive”) and on and after 25 May 2018, Regulation 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of Personal Data and on the free movement of such data (General Data Protection Regulation) (“GDPR”); and (ii) Directive 2002/58/EC concerning the processing of Personal Data and the protection of privacy in the electronic communications sector and applicable national implementations of it (as may be amended, superseded or replaced).
   </li><li>“EEA” means, for the purposes of this DPA, the European Economic Area, United Kingdom and Switzerland.
   </li><li>“Group” means any and all Affiliates that are part of an entity’s corporate group.
   </li>
   <li>“RocketPush Network” means the RocketPush data center facilities, servers, networking equipment, and host software systems (e.g., virtual firewalls) that are within RocketPush control and are used to provide the Services.
   </li><li>“Personal Data” means any information relating to an identified or identifiable natural person.
  </li> <li>“Processing” has the meaning given to it in the GDPR and “process”, “processes” and “processed” shall be interpreted accordingly.
   </li><li>“Security Incident” means any unauthorized or unlawful breach of security that leads to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of or access to Customer Data.
   </li><li>“Services” means any product or service provided by RocketPush to Customer pursuant to the Agreement.
   </li><li>“Standard Contractual Clauses” means Annex C attached to and forming part of this Addendum.
   </li><li>“Sub-processor” means any Data Processor engaged by RocketPush or its Affiliates to assist in fulfilling its obligations with respect to providing the Services pursuant to the Agreement or this DPA. Sub-processors may include third parties or members of the RocketPush Group.
    </li></ul>
    <h2>2. Relationship with the Agreement
   </h2> 2.1 The parties agree that DPA shall replace any existing DPA the parties may have previously entered into in connection with the Services.
    2.2 Except for the changes made by this DPA, the Agreement remains unchanged and in full force and effect. If there is any conflict between this DPA and the Agreement, this DPA shall prevail to the extent of that conflict.
    2.3 Any claims brought under or in connection with this DPA shall be subject to the terms and conditions, including but not limited to, the exclusions and limitations set forth in the Agreement.
    2.4 Any claims against RocketPush or its Affiliates under this DPA shall be brought solely against the entity that is a party to the Agreement. In no event shall any party limit its liability with respect to any individual’s data protection rights under this DPA or otherwise. Customer further agrees that any regulatory penalties incurred by RocketPush in relation to the Customer Data that arise as a result of, or in connection with, Customer’s failure to comply with its obligations under this DPA or any applicable Data Protection Laws shall count toward and reduce RocketPush’s liability under the Agreement as if it were liability to the Customer under the Agreement.
    2.5 No one other than a party to this DPA, its successors and permitted assignees shall have any right to enforce any of its terms.
    2.6 This DPA shall be governed by and construed in accordance with governing law and jurisdiction provisions in the Agreement, unless required otherwise by applicable Data Protection Laws.
    <h2>3. Scope and Applicability of this DPA
  </h2>  3.1 This DPA applies where and only to the extent that RocketPush processes Customer Data that originates from the EEA and/or that is otherwise subject to EU Data Protection Law on behalf of Customer as Data Processor in the course of providing Services pursuant to the Agreement.
    3.2 Part A (being Section 4 – 8 (inclusive) of this DPA, as well as Annexes A, B and C of this DPA) shall apply to the processing of Customer Data within the scope of this DPA from the Effective Date.
    3.3 Part B (being Sections 9-12 (inclusive) of this DPA) shall apply to the processing of Customer Data within the scope of the DPA from and including 25th May 2018. For the avoidance of doubt, Part B shall apply in addition to, and not in substitution for, the terms in Part A.
    Part A: General Data Protection Obligations
    <h2>4. Roles and Scope of Processing
   </h2> 4.1 Role of the Parties. As between RocketPush and Customer, Customer is the Data Controller of Customer Data, and RocketPush shall process Customer Data only as a Data Processor acting on behalf of Customer.
    4.2 Customer Processing of Customer Data. Customer agrees that (i) it shall comply with its obligations as a Data Controller under Data Protection Laws in respect of its processing of Customer Data and any processing instructions it issues to RocketPush; and (ii) it has provided notice and obtained (or shall obtain) all consents and rights necessary under Data Protection Laws for RocketPush to process Customer Data and provide the Services pursuant to the Agreement and this DPA.
    4.3 RocketPush Processing of Customer Data. RocketPush shall process Customer Data only for the purposes described in this DPA and only in accordance with Customer’s documented lawful instructions. The parties agree that this DPA and the Agreement set out the Customer’s complete and final instructions to RocketPush in relation to the processing of Customer Data and processing outside the scope of these instructions (if any) shall require prior written agreement between Customer and RocketPush.
    4.4 Details of Data Processing.
    Subject matter: The subject matter of the data processing under this DPA is the Customer Data.
    Duration: As between RocketPush and Customer, the duration of the data processing under this DPA is until the termination of the Agreement in accordance with its terms.
    Purpose: The purpose of the data processing under this DPA is the provision of the Services to the Customer and the performance of RocketPush’s obligations under the Agreement (including this DPA) or as otherwise agreed by the parties.
    Nature of the processing: RocketPush provides a web push notification service and other related services, as described in the Agreement.
    Categories of data subjects: Any individual accessing and/or using the Services through the Customer’s account (“Users”); and any individual: (i) whose email address is included in the Customer’s Distribution List; (ii) whose information is stored on or collected via the Services, or (iii) to whom Users send emails or otherwise engage or communicate with via the Services (collectively, “Subscribers”).
    Types of Customer Data:
    Customer and Users: identification and contact data (name, address, title, contact details, username); financial information (credit card details, account details, payment information); employment details (employer, job title, geographic location, area of responsibility);
    Subscribers: identification and contact data (name, date of birth, gender, general, occupation or other demographic information, address, title, contact details, including email address), personal interests or preferences (including purchase history, marketing preferences and publicly available social media profile information); IT information (IP addresses, usage data, cookies data, online navigation data, location data, browser data); financial information (credit card details, account details, payment information).
    Notwithstanding anything to the contrary in the Agreement (including this DPA), Customer acknowledges that RocketPush shall have a right to use and disclose data relating to the operation, support and/or use of the Services for its legitimate business purposes, such as billing, account management, technical support, product development and sales and marketing. To the extent any such data is considered Personal Data under Data Protection Laws, RocketPush is the Data Controller of such data and accordingly shall process such data in accordance with the RocketPush Privacy Policy and Data Protection Laws.
    Tracking Technologies. Customer acknowledges that in connection with the performance of the Services, RocketPush employs the use of cookies, unique identifiers, web beacons and similar tracking technologies (“Tracking Technologies”). Customer shall maintain appropriate notice, consent, opt-in and opt-out mechanisms as are required by Data Protection Laws to enable RocketPush to deploy Tracking Technologies lawfully on, and collect data from, the devices of Subscribers.
    <h2>5. Subprocessing
  </h2>  5.1 Authorized Sub-processors. Customer agrees that RocketPush may engage Sub-processors to process Customer Data on Customer’s behalf. The Sub-processors currently engaged by RocketPush and authorized by Customer are listed in Annex A.
    5.2 Sub-processor Obligations. RocketPush shall: (i) enter into a written agreement with the Sub-processor imposing data protection terms that require the Sub-processor to protect the Customer Data to the standard required by Data Protection Laws; and (ii) remain responsible for its compliance with the obligations of this DPA and for any acts or omissions of the Sub-processor that cause RocketPush to breach any of its obligations under this DPA.
    <h2>6. Security
   </h2> 6.1 Security Measures. RocketPush shall implement and maintain appropriate technical and organizational security measures to protect Customer Data from Security Incidents and to preserve the security and confidentiality of the Customer Data, in accordance with RocketPush’s security standards described in Annex B (“Security Measures”).
    6.2 Updates to Security Measures. Customer is responsible for reviewing the information made available by RocketPush relating to data security and making an independent determination as to whether the Services meet Customer’s requirements and legal obligations under Data Protection Laws. Customer acknowledges that the Security Measures are subject to technical progress and development and that RocketPush may update or modify the Security Measures from time to time provided that such updates and modifications do not result in the degradation of the overall security of the Services purchased by the Customer.
    6.3 Customer Responsibilities. Notwithstanding the above, Customer agrees that except as provided by this DPA, Customer is responsible for its secure use of the Services, including securing its account authentication credentials, protecting the security of Customer Data when in transit to and from the Services and taking any appropriate steps to securely encrypt or backup any Customer Data uploaded to the Services.
    <h2>7. Security Reports and Audits
   </h2> 7.1 Customer acknowledges that RocketPush is regularly audited against SSAE 16 and PCI standards by independent third party auditors and internal auditors, respectively.
    7.2 RocketPush shall also provide written responses (on a confidential basis) to all reasonable requests for information made by Customer, including responses to information security and audit questionnaires that are necessary to confirm RocketPush’s compliance with this DPA, provided that Customer shall not exercise this right more than once per year.
    <h2>8. International Transfers
   </h2> 8.1 Data center locations. RocketPush may transfer and process Customer Data anywhere in the world where RocketPush, its Affiliates or its Sub-processors maintain data processing operations. RocketPush shall at all times provide an adequate level of protection for the Customer Data processed, in accordance with the requirements of Data Protection Laws.
    8.2 Alternative Transfer Mechanism. The parties agree that the data export solution identified in Section 8.2 shall not apply if and to the extent that RocketPush adopts an alternative data export solution for the lawful transfer of Personal Data (as recognized under EU Data Protection Laws) outside of the EEA (“Alternative Transfer Mechanism”), in which event, the Alternative Transfer Mechanism shall apply instead (but only to the extent such Alternative Transfer Mechanism extends to the territories to which Personal Data is transferred).
    Part B: GDPR Obligations from 25 May 2018
    <h2>9. Additional Security
  </h2>  9.1 Confidentiality of processing. RocketPush shall ensure that any person who is authorized by RocketPush to process Customer Data (including its staff, agents and subcontractors) shall be under an appropriate obligation of confidentiality (whether a contractual or statutory duty).
    9.2 Security Incident Response. Upon becoming aware of a Security Incident, RocketPush shall notify Customer without undue delay and shall provide timely information relating to the Security Incident as it becomes known or as is reasonably requested by Customer.
    <h2>10. Changes to Sub-processors
    </h2>10.1 RocketPush shall (i) provide an up-to-date list of the Sub-processors it has appointed upon written request from Customer; and (ii) notify Customer (for which email shall suffice) if it adds or removes Sub-processors at least 10 days prior to any such changes.
    10.2 Customer may object in writing to RocketPush’s appointment of a new Sub-processor within thirty (30) calendar days of such notice, provided that such objection is based on reasonable grounds relating to data protection. In such event, the parties shall discuss such concerns in good faith with a view to achieving resolution. If this is not possible, Customer may suspend or terminate the Agreement (without prejudice to any fees incurred by Customer prior to suspension or termination).
    <h2></h2>11. Return or Deletion of Data
    11.1 Upon termination or expiration of the Agreement, RocketPush shall (at Customer’s election) delete or return to Customer all Customer Data (including copies) in its possession or control, save that this requirement shall not apply to the extent RocketPush is required by applicable law to retain some or all of the Customer Data, or to Customer Data it has archived on back-up systems, which Customer Data RocketPush shall securely isolate and protect from any further processing, except to the extent required by applicable law.
    <h2></h2>12. Cooperation
    12.1 The Services provide Customer with a number of controls that Customer may use to retrieve, correct, delete or restrict Customer Data, which Customer may use to assist it in connection with its obligations under the GDPR, including its obligations relating to responding to requests from data subjects or applicable data protection authorities. To the extent that Customer is unable to independently access the relevant Customer Data within the Services, RocketPush shall (at Customer’s expense) provide reasonable cooperation to assist Customer to respond to any requests from individuals or applicable data protection authorities relating to the processing of Personal Data under the Agreement. In the event that any such request is made directly to RocketPush, RocketPush shall not respond to such communication directly without Customer’s prior authorization, unless legally compelled to do so. If RocketPush is required to respond to such a request, RocketPush shall promptly notify Customer and provide it with a copy of the request unless legally prohibited from doing so.
    12.2 If a law enforcement agency sends RocketPush a demand for Customer Data (for example, through a subpoena or court order), RocketPush shall attempt to redirect the law enforcement agency to request that data directly from Customer. As part of this effort, RocketPush may provide Customer’s basic contact information to the law enforcement agency. If compelled to disclose Customer Data to a law enforcement agency, then RocketPush shall give Customer reasonable notice of the demand to allow Customer to seek a protective order or other appropriate remedy unless RocketPush is legally prohibited from doing so.
    12.3 To the extent RocketPush is required under EU Data Protection Law, RocketPush shall (at Customer’s expense) provide reasonably requested information regarding the Services to enable the Customer to carry out data protection impact assessments or prior consultations with data protection authorities as required by law.
    <h2>ANNEX A – List of RocketPush Sub-processors</h2>
    RocketPush uses its Affiliates and a range of third party Sub-processors to assist it in providing the Services (as described in the Agreement). These Sub-processors set out below provide cloud hosting and storage services; content delivery and review services; assist in providing customer support; as well as incident tracking, response, diagnosis and resolution services.
    Sub-processors used by us when working as a processor:
    <ul>
      <li>Sub-Processor : Digital Ocean</li>
      <li>Purpose : Cloud Infrastructure for hosting and offering our services</li>
      <li>Location : singapore</li>
      <li>More Comments : https://www.digitalocean.com/legal/gdpr-faq</li>
      </ul>    

    <h2>Annex B – Security Measures</h2>
    The Security Measures applicable to the Services are described below (as updated from time to time in accordance with Section 6.2 of this DPA).
    1. Information Security Program. RocketPush will maintain an information security program (including the adoption and enforcement of internal policies and procedures) designed to (a) help Customer secure Customer Data against accidental or unlawful loss, access or disclosure, (b) identify reasonably foreseeable and internal risks to security and unauthorized access to the RocketPush Network, and (c) minimize security risks, including through risk assessment and regular testing. RocketPush will designate one or more employees to coordinate and be accountable for the information security program. The information security program will include the following measures:
    1.1 Network Security. The RocketPush Network will be electronically accessible to employees, contractors and any other person as necessary to provide the Services. RocketPush will maintain access controls and policies to manage what access is allowed to the RocketPush Network from each network connection and user, including the use of firewalls or functionally equivalent technology and authentication controls. RocketPush will maintain corrective action and incident response plans to respond to potential security threats.
    1.2 Physical Security
    1.2.1 Physical Access Controls. Physical components of the RocketPush Network are housed in nondescript facilities (the “Facilities”). Physical barrier controls are used to prevent unauthorized entrance to the Facilities both at the perimeter and at building access points. Passage through the physical barriers at the Facilities requires either electronic access control validation (e.g., card access systems, etc.) or validation by human security personnel (e.g., contract or in-house security guard service, receptionist, etc.). Employees and contractors are assigned photo-ID badges that must be worn while the employees and contractors are at any of the Facilities. Visitors are required to sign-in with designated personnel, must show appropriate identification, are assigned a visitor ID badge that must be worn while the visitor is at any of the Facilities, and are continually escorted by authorized employees or contractors while visiting the Facilities.
    1.2.2 Limited Employee and Contractor Access. RocketPush provides access to the Facilities to those employees and contractors who have a legitimate business need for such access privileges. When an employee or contractor no longer has a business need for the access privileges assigned to him/her, the access privileges are promptly revoked, even if the employee or contractor continues to be an employee of RocketPush or its affiliates.
    1.2.3 Physical Security Protections. All access points (other than main entry doors) are maintained in a secured (locked) state. Access points to the Facilities are monitored by video surveillance cameras designed to record all individuals accessing the Facilities. RocketPush also maintains electronic intrusion detection systems designed to detect unauthorized access to the Facilities, including monitoring points of vulnerability (e.g., primary entry doors, emergency egress doors, roof hatches, dock bay doors, etc.) with door contacts, glass breakage devices, interior motion- detection, or other devices designed to detect individuals attempting to gain access to the Facilities. All physical access to the Facilities by employees and contractors is logged and routinely audited.
    2. Continued Evaluation. RocketPush will conduct periodic reviews of the security of its RocketPush Network and adequacy of its information security program as measured against industry security standards and its policies and procedures. RocketPush will continually evaluate the security of its RocketPush Network and associated Services to determine whether additional or different security measures are required to respond to new security risks or findings generated by the periodic reviews.
    <h2>Annex C – Standard Contractual Clauses</h2>
    For the purposes of Article 26(2) of Directive 95/46/EC for the transfer of personal data to processors established in third countries which do not ensure an adequate level of data protection
    The entity identified as “Customer” in the Addendum (the “data exporter”) and RocketPush (the “data importer”) each a “party”; together “the parties”,
    HAVE AGREED on the following Contractual Clauses (the Clauses) in order to adduce adequate safeguards with respect to the protection of privacy and fundamental rights and freedoms of individuals for the transfer by the data exporter to the data importer of the personal data specified in Appendix 1.
    <h3>Clause 1</h3>
    Definitions
    For the purposes of the Clauses:
    ‘personal data’, ‘special categories of data’, ‘process/processing’, ‘controller’, ‘processor’, ‘data subject’ and ‘supervisory authority’ shall have the same meaning as in Directive 95/46/EC of the European Parliament and of the Council of 24 October 1995 on the protection of individuals with regard to the processing of personal data and on the free movement of such data;
    ‘the data exporter’ means the controller who transfers the personal data;
    ‘the data importer’ means the processor who agrees to receive from the data exporter personal data intended for processing on his behalf after the transfer in accordance with his instructions and the terms of the Clauses and who is not subject to a third country’s system ensuring adequate protection within the meaning of Article 25(1) of Directive 95/46/EC;
    ‘the subprocessor’ means any processor engaged by the data importer or by any other subprocessor of the data importer who agrees to receive from the data importer or from any other subprocessor of the data importer personal data exclusively intended for processing activities to be carried out on behalf of the data exporter after the transfer in accordance with his instructions, the terms of the Clauses and the terms of the written subcontract;
    ‘the applicable data protection law’ means the legislation protecting the fundamental rights and freedoms of individuals and, in particular, their right to privacy with respect to the processing of personal data applicable to a data controller in the Member State in which the data exporter is established;
    ‘technical and organisational security measures’ means those measures aimed at protecting personal data against accidental or unlawful destruction or accidental loss, alteration, unauthorised disclosure or access, in particular where the processing involves the transmission of data over a network, and against all other unlawful forms of processing.
    <h3>Clause 2
   </h3> Details of the transfer
    The details of the transfer and in particular the special categories of personal data where applicable are specified in Appendix 1 which forms an integral part of the Clauses.
    <h3>Clause 3
    </h3>Third-party beneficiary clause
    The data subject can enforce against the data exporter this Clause, Clause 4(b) to (i), Clause 5(a) to (e), and (g) to (j), Clause 6(1) and (2), Clause 7, Clause 8(2), and Clauses 9 to 12 as third-party beneficiary.
    The data subject can enforce against the data importer this Clause, Clause 5(a) to (e) and (g), Clause 6, Clause 7, Clause 8(2), and Clauses 9 to 12, in cases where the data exporter has factually disappeared or has ceased to exist in law unless any successor entity has assumed the entire legal obligations of the data exporter by contract or by operation of law, as a result of which it takes on the rights and obligations of the data exporter, in which case the data subject can enforce them against such entity.
    The data subject can enforce against the subprocessor this Clause, Clause 5(a) to (e) and (g), Clause 6, Clause 7, Clause 8(2), and Clauses 9 to 12, in cases where both the data exporter and the data importer have factually disappeared or ceased to exist in law or have become insolvent, unless any successor entity has assumed the entire legal obligations of the data exporter by contract or by operation of law as a result of which it takes on the rights and obligations of the data exporter, in which case the data subject can enforce them against such entity. Such third-party liability of the subprocessor shall be limited to its own processing operations under the Clauses.
    The parties do not object to a data subject being represented by an association or other body if the data subject so expressly wishes and if permitted by national law.
    <h3>Clause 4
   </h3> Obligations of the data exporter
    The data exporter agrees and warrants:
    that the processing, including the transfer itself, of the personal data has been and will continue to be carried out in accordance with the relevant provisions of the applicable data protection law (and, where applicable, has been notified to the relevant authorities of the Member State where the data exporter is established) and does not violate the relevant provisions of that State;
    that it has instructed and throughout the duration of the personal data processing services will instruct the data importer to process the personal data transferred only on the data exporter’s behalf and in accordance with the applicable data protection law and the Clauses;
    that the data importer will provide sufficient guarantees in respect of the technical and organisational security measures specified in Appendix 2 to this contract;
    that after assessment of the requirements of the applicable data protection law, the security measures are appropriate to protect personal data against accidental or unlawful destruction or accidental loss, alteration, unauthorised disclosure or access, in particular where the processing involves the transmission of data over a network, and against all other unlawful forms of processing, and that these measures ensure a level of security appropriate to the risks presented by the processing and the nature of the data to be protected having regard to the state of the art and the cost of their implementation;
    that it will ensure compliance with the security measures;
    that, if the transfer involves special categories of data, the data subject has been informed or will be informed before, or as soon as possible after, the transfer that its data could be transmitted to a third country not providing adequate protection within the meaning of Directive 95/46/EC;
    to forward any notification received from the data importer or any subprocessor pursuant to Clause 5(b) and Clause 8(3) to the data protection supervisory authority if the data exporter decides to continue the transfer or to lift the suspension;
    to make available to the data subjects upon request a copy of the Clauses, with the exception of Appendix 2, and a summary description of the security measures, as well as a copy of any contract for subprocessing services which has to be made in accordance with the Clauses, unless the Clauses or the contract contain commercial information, in which case it may remove such commercial information;
    that, in the event of subprocessing, the processing activity is carried out in accordance with Clause 11 by a subprocessor providing at least the same level of protection for the personal data and the rights of data subject as the data importer under the Clauses; and
    that it will ensure compliance with Clause 4(a) to (i).
    <h3></h3>Clause 5
    Obligations of the data importer
    The data importer agrees and warrants:
    to process the personal data only on behalf of the data exporter and in compliance with its instructions and the Clauses; if it cannot provide such compliance for whatever reasons, it agrees to inform promptly the data exporter of its inability to comply, in which case the data exporter is entitled to suspend the transfer of data and/or terminate the contract;
    that it has no reason to believe that the legislation applicable to it prevents it from fulfilling the instructions received from the data exporter and its obligations under the contract and that in the event of a change in this legislation which is likely to have a substantial adverse effect on the warranties and obligations provided by the Clauses, it will promptly notify the change to the data exporter as soon as it is aware, in which case the data exporter is entitled to suspend the transfer of data and/or terminate the contract;
    that it has implemented the technical and organisational security measures specified in Appendix 2 before processing the personal data transferred;
    that it will promptly notify the data exporter about:
    any legally binding request for disclosure of the personal data by a law enforcement authority unless otherwise prohibited, such as a prohibition under criminal law to preserve the confidentiality of a law enforcement investigation,
    any accidental or unauthorised access, and
    any request received directly from the data subjects without responding to that request, unless it has been otherwise authorised to do so;
    to deal promptly and properly with all inquiries from the data exporter relating to its processing of the personal data subject to the transfer and to abide by the advice of the supervisory authority with regard to the processing of the data transferred;
    at the request of the data exporter to submit its data processing facilities for audit of the processing activities covered by the Clauses which shall be carried out by the data exporter or an inspection body composed of independent members and in possession of the required professional qualifications bound by a duty of confidentiality, selected by the data exporter, where applicable, in agreement with the supervisory authority;
    to make available to the data subject upon request a copy of the Clauses, or any existing contract for subprocessing, unless the Clauses or contract contain commercial information, in which case it may remove such commercial information, with the exception of Appendix 2 which shall be replaced by a summary description of the security measures in those cases where the data subject is unable to obtain a copy from the data exporter;
    that, in the event of subprocessing, it has previously informed the data exporter and obtained its prior written consent;
    that the processing services by the subprocessor will be carried out in accordance with Clause 11;
    to send promptly a copy of any subprocessor agreement it concludes under the Clauses to the data exporter.
    Mandatory requirements of the national legislation applicable to the data importer which do not go beyond what is necessary in a democratic society on the basis of one of the interests listed in Article 13(1) of Directive 95/46/EC, that is, if they constitute a necessary measure to safeguard national security, defence, public security, the prevention, investigation, detection and prosecution of criminal offences or of breaches of ethics for the regulated professions, an important economic or financial interest of the State or the protection of the data subject or the rights and freedoms of others, are not in contradiction with the standard contractual clauses. Some examples of such mandatory requirements which do not go beyond what is necessary in a democratic society are, inter alia, internationally recognized sanctions, tax-reporting requirements or anti-money-laundering reporting requirements.
    <h3>Clause 6
    </h3>Liability
    The parties agree that any data subject, who has suffered damage as a result of any breach of the obligations referred to in Clause 3 or in Clause 11 by any party or subprocessor is entitled to receive compensation from the data exporter for the damage suffered.
    If a data subject is not able to bring a claim for compensation in accordance with paragraph 1 against the data exporter, arising out of a breach by the data importer or his subprocessor of any of their obligations referred to in Clause 3 or in Clause 11, because the data exporter has factually disappeared or ceased to exist in law or has become insolvent, the data importer agrees that the data subject may issue a claim against the data importer as if it were the data exporter, unless any successor entity has assumed the entire legal obligations of the data exporter by contract of by operation of law, in which case the data subject can enforce its rights against such entity. The data importer may not rely on a breach by a subprocessor of its obligations in order to avoid its own liabilities.
    If a data subject is not able to bring a claim against the data exporter or the data importer referred to in paragraphs 1 and 2, arising out of a breach by the subprocessor of any of their obligations referred to in Clause 3 or in Clause 11 because both the data exporter and the data importer have factually disappeared or ceased to exist in law or have become insolvent, the subprocessor agrees that the data subject may issue a claim against the data subprocessor with regard to its own processing operations under the Clauses as if it were the data exporter or the data importer, unless any successor entity has assumed the entire legal obligations of the data exporter or data importer by contract or by operation of law, in which case the data subject can enforce its rights against such entity. The liability of the subprocessor shall be limited to its own processing operations under the Clauses.
    <h3>Clause 7
   </h3> Mediation and jurisdiction
    1. The data importer agrees that if the data subject invokes against it third-party beneficiary rights and/or claims compensation for damages under the Clauses, the data importer will accept the decision of the data subject:
    to refer the dispute to mediation, by an independent person or, where applicable, by the supervisory authority;
    to refer the dispute to the courts in the Member State in which the data exporter is established.
    The parties agree that the choice made by the data subject will not prejudice its substantive or procedural rights to seek remedies in accordance with other provisions of national or international law.
    <h3>Clause 8
   </h3> Cooperation with supervisory authorities
    The data exporter agrees to deposit a copy of this contract with the supervisory authority if it so requests or if such deposit is required under the applicable data protection law.
    The parties agree that the supervisory authority has the right to conduct an audit of the data importer, and of any subprocessor, which has the same scope and is subject to the same conditions as would apply to an audit of the data exporter under the applicable data protection law.
    The data importer shall promptly inform the data exporter about the existence of legislation applicable to it or any subprocessor preventing the conduct of an audit of the data importer, or any subprocessor, pursuant to paragraph 2. In such a case the data exporter shall be entitled to take the measures foreseen in Clause 5 (b).
    <h3>Clause 9
    </h3> Governing Law
    The Clauses shall be governed by the law of the Member State in which the data exporter is established.
    <h3>Clause 10
   </h3> Variation of the contract
    The parties undertake not to vary or modify the Clauses. This does not preclude the parties from adding clauses on business related issues where required as long as they do not contradict the Clause.
    <h3>Clause 11
    </h3>Subprocessing
    The data importer shall not subcontract any of its processing operations performed on behalf of the data exporter under the Clauses without the prior written consent of the data exporter. Where the data importer subcontracts its obligations under the Clauses, with the consent of the data exporter, it shall do so only by way of a written agreement with the subprocessor which imposes the same obligations on the subprocessor as are imposed on the data importer under the Clauses. Where the subprocessor fails to fulfil its data protection obligations under such written agreement the data importer shall remain fully liable to the data exporter for the performance of the subprocessor’s obligations under such agreement.
    The prior written contract between the data importer and the subprocessor shall also provide for a third- party beneficiary clause as laid down in Clause 3 for cases where the data subject is not able to bring the claim for compensation referred to in paragraph 1 of Clause 6 against the data exporter or the data importer because they have factually disappeared or have ceased to exist in law or have become insolvent and no successor entity has assumed the entire legal obligations of the data exporter or data importer by contract or by operation of law. Such third-party liability of the subprocessor shall be limited to its own processing operations under the Clauses.
    The provisions relating to data protection aspects for subprocessing of the contract referred to in paragraph 1 shall be governed by the law of the Member State in which the data exporter is established.
    The data exporter shall keep a list of subprocessing agreements concluded under the Clauses and notified by the data importer pursuant to Clause 5 (j), which shall be updated at least once a year. The list shall be available to the data exporter’s data protection supervisory authority.
    <h3>Clause 12
    </h3>Obligation after the termination of personal data processing services
    The parties agree that on the termination of the provision of data processing services, the data importer and the subprocessor shall, at the choice of the data exporter, return all the personal data transferred and the copies thereof to the data exporter or shall destroy all the personal data and certify to the data exporter that it has done so, unless legislation imposed upon the data importer prevents it from returning or destroying all or part of the personal data transferred. In that case, the data importer warrants that it will guarantee the confidentiality of the personal data transferred and will not actively process the personal data transferred anymore.
    The data importer and the subprocessor warrant that upon request of the data exporter and/or of the supervisory authority, it will submit its data processing facilities for an audit of the measures referred to in paragraph 1.
    <h3>APPENDIX 1 TO THE STANDARD CONTRACTUAL CLAUSES
   </h3> Data exporter
    The data exporter is the entity identified as “Customer” in the Addendum.
    Data importer
    The data importer is RocketPush, a provider of web services.
    Data subjects
    Data subjects include the data exporter’s customers and end-users.
    Categories of data
    The personal data relating to individuals which is uploaded onto the RocketPush Services by the data exporter.
    Processing operations
    The personal data transferred will be subject to the following basic processing activities (as applicable):
    Compute, Storage and Content Delivery on the RocketPush Network
    <h3>APPENDIX 2 TO THE STANDARD CONTRACTUAL CLAUSES
    </h3>This Appendix forms part of the Clauses and must be completed and signed by the parties. By signing the signature page on page 1 of this Addendum, the parties will be deemed to have signed this Appendix 2.
    Description of the technical and organizational security measures implemented by the data importer in accordance with Clauses 4(d) and 5(c) (or document/legislation attached):
    The technical and organizational security measures implemented by the data importer are as described in the Addendum.
    Last updated: November 27, 2020


     
    </div>
  );
};

Gdpr.propTypes = {};

export default Gdpr;
